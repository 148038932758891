
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../static/fonts/quick.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
  font-family: 'Chakra';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../static/fonts/ChakraPetch-SemiBold.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* برای تغییر دیفالت روی دارک رنگ های زیر را دارک میکنی و تو کامپوننت پروفایل روور شرط را برعکس */

:root {
  --hoory-25:#252525; /* رنگ متن مشکی متن صفحات */
  --hoory-50:#37546D; /* رنگ متن منو ها و آیکون ها */
  --hoory-75:#e6e7ea; /* رنگ بوردرها */
  --hoory-100:#fff; /* رنگ بکگراند سکشن ها */
  --hoory-125:#f4f6fd; /* رنگ بکگراند خاکستری لایه بیس */
  --hoory-175:#f4f6fd;  /*  بکگراند دکمه های بنفش */
  --hoory-200:#6362e7;  /*  متن دکمه های بنفش */
}

* {
    box-sizing: border-box
  }

  html , body {
    scroll-behavior: smooth;
  }
  
  span,
  div,
  input,
  textarea,
  button,
  select,
  a,
  pre {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: 'Chakra', sans-serif !important;
  }
  
  body {
    font-family: 'Chakra', sans-serif !important;
    color: var(--hoory-25);
    background: var(--hoory-100);
  }


.auth  input:-webkit-autofill {
    background: transparent;
  }

  /* .auth  input:-webkit-autofill,
  .auth input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
} */
/* .auth input[data-autocompleted] {
    background-color: transparent !important;
} */
  
/* Style For CkSkilton */

.CSkilton {
  width: 100%;
  height: 100%;
  background: var(--hoory-125);
  /* box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15); */
  overflow: hidden;
  transition: all 0.2s;
  position: relative;
}

.CSkilton::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 2000px;
  width: 150px;
  transform: rotate(45deg);
  background: linear-gradient(to right, transparent 0%, var(--hoory-100) 50%, transparent 100%);
  animation: skeleton-album-load 1.9s cubic-bezier(0.4, 0.0, 0.9, 1) infinite;
}

@keyframes skeleton-album-load {
  from {
      left: -150px;
      top: -2000px;
  }
  to {
      left: 100%;
      top: 100%;
  }
}



/* Style For CModal (Custom modal) */

.CModal {
  position: fixed;
  background: #0000009a;
  z-index: -1000;
  transition: all 0.6s;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.CModal-active {
  top: 0;
  visibility: unset;
  transition: all 0.3s;
  overflow: auto;
  opacity: 1;
  z-index: 1000;
}

.CModal-cont {
  margin: 0 auto;
  width: 500px;
  max-width: 95%;
  min-height: 50px;
  margin-top: 50px;
  background: var(--hoory-175);
  border-radius: 5px;
  margin-bottom: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 13px;
}

.CModel-head {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.CModel-head h4 {
  font-size: 14px;
}

.CModel-Bottom {
  width: 100%;
  height: 50px;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.CModel-Bottom-btn-ok {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  background: var(--hoory-200);
  color: #fff;
  cursor: pointer;
}

.CModel-Bottom-btn-cancel {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border: 1px solid #ff3a44;
  border-radius: 3px;
  background: none;
  color: #ff3a44;
  margin-left: 10px;
  cursor: pointer;
}

.CModel-Bottom-btn-del {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  /* border:1px solid tomato; */
  border-radius: 3px;
  background: #ff3a44;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.CModel-Bottom-btn-info {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border: 1px solid var(--hoory-200-);
  border-radius: 3px;
  background: none;
  color: var(--hoory-200);
  margin-left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  .CModal-cont {
    width: 95%;
  }
}



/* Style For Editor */

.trains-editor .ql-container {
  height: 200px;
  background: var(--hoory-100);
  border-color: var(--hoory-75);
  border-radius: 0 0 10px 10px;
}

.trains-editor .ql-editor:focus {
  outline: none;
}

.trains-editor .ql-toolbar {
  border-radius: 10px 10px 0 0;
  border-color: var(--hoory-75);
}



/* Style For Toggle */

.table-toggle-main {
  width: 60px;
  height: 27px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 30px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  /* margin-top: -18px; */
  background: #f4f6fd;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
}

.table-toggle input {
  display: none;
}

.table-toggle-main::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #60a5fa;
  margin-top: 3px;
  left: 6px;
  transition: all 0.3s;
  right: unset;
}

.table-toggle input:checked~.table-toggle-main {
  background: var(--dayan-orange-50);
  transition: all 0.3s;
}

.table-toggle input:checked~.table-toggle-main::before {
  left: unset;
  right: 6px;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .table-toggle-main {
      height: 25px;
      width: 50px;
  }
  .table-toggle-main::before {
      margin-top: 5px;
      width: 15px;
      height: 15px;
  }
}
