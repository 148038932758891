/* Style For Custom Table */

.Ctable-parent {
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

.Ctable-parent::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.Ctable {
    border-collapse: collapse;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    min-width: 1000px;
    border-collapse: separate;
    border-spacing: 0 0;
    background: var(--hoory-175);
}

.Ctable tr {
    /* box-shadow: 0 4px 8px 0 hsla(0, 0%, 76.9%, .5); */
    /* border-radius: 10px; */
}

.Ctable-head {
    height: 50px;
    /* border-radius: 10px; */
}

.Ctable-head th {
    border-bottom: 1px solid  var(--hoory-75);
    border-top: 1px solid  var(--hoory-75);
    color: #aaaebe;
    font-weight: 600;
    text-align: left;
    padding-left: 12px;
    position: relative;
    font-size: 12px;
}

/* .Ctable-head th::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 60%;
    background: #a2958e;
    left: 0;
    top: 20%;
} */

.Ctable-head th:first-child {
    /* border-radius: 0 10px 10px 0; */
}

.Ctable-head th:last-child {
    /* border-radius: 10px 0 0 10px; */
}

.Ctable-head th:last-child::after {
    display: none;
}

.Ctable td {
    text-align: left;
    padding: 18px 0 18px 12px;
    font-size: 12px;
    position: relative;
    background-color: var(--hoory-100);
    border-bottom: 1px solid var(--hoory-75);

}

/* .Ctable td::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 60%;
    background: #e2e2e2;
    left: 0;
    top: 20%;
} */

/* .Ctable td:first-child {
    border-radius: 0 10px 10px 0;
}

.Ctable td:last-child {
    border-radius: 10px 0 0 10px;
} */

.Ctable td:last-child::after {
    display: none;
}

.Ctable-counter {
    width: 100%;
    margin-top: 20px;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}

@media only screen and (max-width:650px) {
    .Ctable-head-item,
    .Ctable-row-item {
        justify-content: center;
        padding-right: 0;
    }
    .Ctable {
        border-spacing: 0 14px;
    }
}